import Field from "../Field";
import { Controller, FieldValues, Control } from "react-hook-form";
import ImageUpload from "./ImageUpload";
import type { CoreFieldProps } from "../types";

interface InputProps<T extends FieldValues> extends CoreFieldProps<T> {
  type?: string;
  control: Control<T>;
}

export default function ImageUploadField<T extends FieldValues>(props: InputProps<T>) {
  const {
    name,
    required = true,
    disabled = false,
    control,
  } = props;

  return (
    <Field {...props} resetField={undefined}>
      <Controller
        name={name}
        control={control}
        rules={{
          ...(required === true && { required: "This field is required" } )
        }}
        shouldUnregister={true}
        render={({ field: { value, onChange, onBlur, ref, ...field } }) => {
          return (
            <ImageUpload
              {...field}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              forwardRef={ref}
              {...(required === true && { required } )}
              disabled={disabled}
            />
          );
        }}
      />
    </Field>
  );
}