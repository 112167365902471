import { useCallback, useContext, useEffect, useState, useRef } from "react";

import {
  useNavigate,
  useLocation,
  useParams
} from "react-router-dom";

import {
  ApolloClient,
  ApolloProvider,
  gql,
  useLazyQuery
} from "@apollo/client";

import Helmet from "react-helmet";

import { SubscriptionClient } from "subscriptions-transport-ws";
import { Partytown } from "@builder.io/partytown/react";

import { useIdleTimer } from "react-idle-timer";

import { Loading } from "@evos/components";
import GlobalNotificationModal from "components/GlobalNotificationModal";

import Routes from "Routes";
import supportedBrowsers from "supportedBrowsers";

import { AuthContext } from "lib/context/auth";
import {
  checkAuth,
  initiateTokens
} from "lib/api/salesforce";
import usePWAServiceWorker from "lib/hooks/usePWAServiceWorker";
import graphqlAuthSetup from "lib/api/graphqlAuthSetup";
import QueueMutationsLink from "lib/api/QueueMutationsLink";
// import useInterval from "lib/useInterval";

import type { ReactNode } from "react";
import type { NormalizedCacheObject } from "@apollo/client";
import type { UserConnection } from "types";

function useAuth() {
  return useContext(AuthContext);
}

function App() {
  const auth = useAuth();
  const darkMode = auth?.darkMode ?? false;

  /* Apollo setup */
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const [wsClient, setWsClient] = useState<SubscriptionClient | null>(null);
  const [queueLink, setQueueLink] = useState<QueueMutationsLink | null>(null);

  const initialiseApollo = useCallback(() => {
    graphqlAuthSetup(auth).then((clients) => {
      setClient(clients.client);
      setWsClient(clients.wsClient);
      setQueueLink(clients.queueLink);
    });
  }, [auth]);

  useEffect(() => {
    if (client == null) {
      initialiseApollo();
    }
  }, [auth, client, initialiseApollo]);

  let retries = 0;

  wsClient?.onDisconnected(() => {
    retries++;
    if (retries === 5) {
      auth?.setSubscriptionsOffline(true);
      queueLink?.close();
    }
  });

  wsClient?.onConnected(() => {
    queueLink?.open();
    window.localStorage.setItem("subscriptionsOffline", "false");
  });

  wsClient?.onReconnected(() => {
    retries = 0;
    auth?.setSubscriptionsOffline(false);
    queueLink?.open();
  });

  wsClient?.onError(() => { return; }); // Don't print ws errors to console

  /* PWA Service Worker setup */
  const { newUpdateAvailable, waitingWorker, reloadPage } = usePWAServiceWorker(); 
  const isUpdateAvailable = (waitingWorker != null) && newUpdateAvailable;

  return (
    <>
      { (process.env.REACT_APP_ENV === "production")
        && <>
          <Partytown forward={["dataLayer.push"]} />
          <Helmet>
            <script type="text/partytown" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG}`}></script>
            <script type="text/partytown">
              {
                `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG}');`
              }
            </script>
          </Helmet>
        </>
      }
      { (client != null)
        && <ApolloProvider client={client!}>
          <div id="backgroundColor" style={{
            backgroundColor: "#F7F9FF",
            color: (darkMode ?? false) ? "white" : "black"
          }}>
            <Routes initialiseApollo={initialiseApollo} reloadPage={reloadPage} />
          </div>
        </ApolloProvider>
      }
      <GlobalNotificationModal
        user={auth?.user}
        expiredTokenError={auth?.expiredTokenError}
        isUpdateAvailable={isUpdateAvailable}
        reloadPage={reloadPage}
        supportedBrowsers={supportedBrowsers}
      />
    </>
  );
}

export function RequireAuth({ children }: { children: ReactNode }) {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth()!;
  const params = useParams();

  const userType = window.localStorage.getItem("userType")!;

  const ampchargeRetries = useRef(0);

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60 * 20, // 20 minutes
    disabled: auth?.user == null,
    onActive: async () => {
      const checkAuthResponse = await checkAuth();
      const isAuthenticated = checkAuthResponse["message"] === "Authenticated";
      if (!isAuthenticated) {
        auth?.setExpiredTokenError(true);
      }
    }
  });

  useEffect(() => {
    const startInOfflineMode = () => {
      auth?.setSubscriptionsOffline(true);
      navigate("/authorization?skip=true", { replace: true });
    };

    const checkIfAmpcharge = async () => {
      const ampchargeResponse = await fetch(`${process.env.REACT_APP_GRAPHQL_API_URL}/ampcharge-check/${params.serial}`);

      if (ampchargeResponse?.ok === true) {
        const ampchargeJsonBody = await ampchargeResponse?.json();
        if (ampchargeJsonBody?.status === "success") {
          if (Boolean(ampchargeJsonBody?.serviced_by_ampol) === true) {
            if (ampchargeJsonBody?.model.includes("SB") === true) {
              window.location.replace(`${process.env.REACT_APP_INSTALLATION_MANUAL_SB7_AMPCHARGE}`);
            } else {
              window.location.replace("https://ampcharge.ampol.com.au/ev-chargers/set-up");
            }
          } else {
            await login().catch((error) => {
              console.error(error);
              startInOfflineMode();
            });
          }
        } else {
          console.error(`Ampcharge check failed for ${params.serial}`);
        }
      } else if (ampchargeResponse?.status === 429) {
        console.warn("Rate limit error for Ampcharge status check. Trying again in 3 seconds...");
        if (ampchargeRetries.current < 3) {
          setTimeout(() => {
            ampchargeRetries.current++;
            checkIfAmpcharge();
          }, 3000);
        } else {
          console.error("Ampcharge status check failed after 3 retries");
        }
      } else if ((ampchargeResponse?.status ?? 0) >= 500 && (ampchargeResponse?.status ?? 0) < 600) {
        console.error("Server error getting Ampcharge status");
      } else if (ampchargeResponse?.status === 403) {
        console.error("Forbidden error response for Ampcharge status check");
      }
    };

    const login = async () => {
      const checkAuthResponse = await checkAuth();
      const isAuthenticated = checkAuthResponse["message"] === "Authenticated";

      window.localStorage.setItem("redirectUrl", location?.pathname + location?.search);

      // Backend will return "skip" as the login-url if the user is already logged in
      if (isAuthenticated) {
        navigate("/authorization?skip=true", {state: location.state, replace: true });
      } else {
        window.location.replace("/login");
      }
    };

    const initiateLoginProcess = async () => {
      // Regex checks if pathname is from the charger QR code
      // Will look like "/c/serial"
      if (/^\/c\/[A-Za-z0-9]\w+/.test(location?.pathname)) {
        await checkIfAmpcharge();
      } else {
        await login().catch((error) => {
          console.error(error);
          startInOfflineMode();
        });
      }
    };

    if ((auth?.user) == null) {
      initiateLoginProcess();
    }

    if (auth?.user != null && auth.expiredTokenError === true && isIdle()) {
      window.location.reload();
    }

  }, [auth, isIdle, location, navigate, params.serial, userType]);

  if ((auth?.user) == null) {
    return <Loading />;
  } else {
    return (
      <>
        {children}
      </>
    );
  }
}

const USER_DETAILS = gql`
  query User {
    user {
      edges {
        node {
          id
          name
          email
          roles
          salesforce_role
        }
      }
    }
  }
`;

export function Authorization({initialiseApollo}: {initialiseApollo: () => void}) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth()!;

  const [cookieAssigned, setCookieAssigned] = useState(false);

  const [getUser, { data }] = useLazyQuery<UserConnection>(USER_DETAILS);

  useEffect(() => {
    // Controller to abort the fetchToken request if the component is unmounted
    const controller = new AbortController();

    const from = window.localStorage.getItem("redirectUrl");

    const locationSearch = location.search;
    const searchParams = new URLSearchParams(locationSearch);

    // Salesforce returns the following URL params:
    // display, code, sfdc_community_url, sfdc_community_id
    const authCode = searchParams.get("code")!;

    // If user is already logged in, the RequireAuth component
    // sets a "skip" param in the URL to skip the login process
    const skip = searchParams.get("skip");

    const fetchToken = async () => {
      if (skip == null) {
        await initiateTokens(controller, authCode)
          .then(
            // Fetch user data once tokens are successfully
            // fetched in the backend
            () => {
              setCookieAssigned(true);
              getUser();
            }
          )
          .catch(() => {
            return auth?.setExpiredTokenError(true);
          });
      } else {
        setCookieAssigned(true);
        getUser()
          // If the user adds the skip param to the URL manually without being logged in,
          // this query will fail and the user will be redirected to the base URL
          .catch(() => navigate("/", { replace: true }));
      }
    };

    if (data == null) {
      if (navigator.onLine) {
        fetchToken().catch(console.error);
      } else {
        getUser();
      }
    } else {
      const userNode = data?.user?.edges[0]?.node;
      const userDetails = {
        display_name: userNode.name,
        email: userNode.email,
        user_id: userNode.id,
        salesforce_role: userNode.salesforce_role,
        roles: userNode.roles
      };

      auth.signin(userDetails, () => {
        if (from !== null) {
          navigate(from!, { state: location.state, replace: true });
        } else {
          navigate("/", { state: location.state, replace: true });
        }
        // Re-initialise Apollo after login to send new cookie to subscriptions
        initialiseApollo();
      });
    }

    return () => controller?.abort();
  }, [data, auth, location.search, getUser, navigate, location.state, initialiseApollo]);

  if (cookieAssigned || !navigator.onLine) {
    return (
      <Loading />
    );
  } else {
    return <Loading />;
  }
}

export function RedirectSerial() {
  const auth = useAuth()!;
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.salesforce_role === "Installer") {
      navigate(
        "/install", {
          state: {
            serial: params.serial
          },
          replace: true
        }
      );
    } else if (auth?.user?.salesforce_role === "Fleet_Manager") {
      navigate("/asset-management/c/" + params.serial);
    } else if (auth?.user?.salesforce_role === "Driver") {
      navigate("/driver/c/" + params.serial);
    }
  }, [auth, navigate, params]);


  return <Loading />;
}

export function PublicRoute({ children, reloadPage }: { children: ReactNode, reloadPage: () => void }) {
  /* Prevents public routes from becoming stale when new updates are pushed */
  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onActive: async () => {
      reloadPage();
    }
  });

  return children;
}

export default App;